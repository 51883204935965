import { generateUUIDv4 } from '../utils/uuid'
import { getItem, setItem, removeItem } from '../utils/session-storage'

const sessionIdStorageKey = 'THRIVE_SEGMENT_SESSION_ID'
const sessionIdExpirationTime = 30 * 60 * 1000 // 30 minutes in ms
let sessionIdExpirationTimeout = null

const sessionExpiration = () => {
    removeItem(sessionIdStorageKey)
}

export const getSegmentSession = () => {
    if (sessionIdExpirationTimeout) clearTimeout(sessionIdExpirationTimeout)
    let sessionID = getItem(sessionIdStorageKey)

    if (!sessionID) {
        sessionID = generateUUIDv4()
        setItem(sessionIdStorageKey, sessionID)
    }

    sessionIdExpirationTimeout = setTimeout(
        sessionExpiration,
        sessionIdExpirationTime
    )

    return sessionID
}
